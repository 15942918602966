<template>
  <!-- Users list filter -->
  <UsersFilter
    v-if="allGames()"
    @filter="setFilter"
    aria-describedby="filterHelp"
  />

  <!-- Users list Inventory filter -->
  <div v-else>
    <UsersFilterInventory
      :game="game"
      @filter="setFilter"
      aria-describedby="filterInventoryHelp"
    />
  </div>

  <!-- Users list -->
  <div v-if="!error">
    <div v-for="(user, index) in usersList" :key="index">
      <!-- Horizontal separator or empty div depending on index -->
      <hr v-if="index" />
      <div v-else class="mb-4"></div>

      <!-- User name -->
      <h5>{{ index + 1 }}. {{ user.Username }}</h5>

      <!-- User attributes -->
      <div class="description">
        <div :name="AttributesMap(user.Attributes)['sub']">
          Id: {{ AttributesMap(user.Attributes)["sub"] }}
        </div>

        <div>Email: {{ AttributesMap(user.Attributes)["email"] }}</div>

        <div>
          Email verified:
          {{
            AttributesMap(user.Attributes)["email_verified"] == "true"
              ? "Yes"
              : "No"
          }}
        </div>

        <PlayerOnline
          :game="game"
          :sub="AttributesMap(user.Attributes)['sub']"
          :setOnline="user.online"
          @online="(v) => (user.online = v)"
        />

        <div v-if="!allGames()">
          Save size:
          <span
            :class="
              sizeColor(AttributesMap(user.Attributes)['data-length-class'])
            "
            >{{
              AttributesMap(user.Attributes)["data-length-human"] || "None"
            }}</span
          ><br />
          Fraction: {{ AttributesMap(user.Attributes)["fraction"] || "None" }}
        </div>
      </div>

      <!-- Users list actions -->
      <UsersListActions
        :game="game"
        :sub="AttributesMap(user.Attributes)['sub']"
        :online="user.online ? user.online.online : false"
      />
    </div>
  </div>

  <!-- Error message -->
  <div v-else class="alert alert-danger mt-3" role="alert">{{ error }}</div>

  <!-- Next page button -->
  <div v-if="previous && !error" class="text-center">
    <hr />
    <a
      class="btn btn-secondary"
      :class="processGetPage ? 'disabled d-none' : ''"
      @click="getNextPage(filter)"
    >
      <i v-if="!processGetPage" class="bi bi-box-arrow-down"></i>
      <i v-else class="bi bi-arrow-clockwise"></i>
    </a>
  </div>

  <!-- Loading indicator -->
  <div v-if="processGetPage" class="d-flex justify-content-center m-4">
    <div class="spinner-border text-secondary" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</template>

<script>
import PlayerOnline from "./PlayerOnline.vue";
import UsersFilter from "./UsersFilter.vue";
import UsersFilterInventory from "./UsersFilterInventory.vue";
import UsersListActions from "./UsersListActions.vue";

const allGames = "All games";
const cmdUsersList = "users.list/";
const cmdPlayersOnline = "players.online";
const cmdPlayersOnlineUnsubscribe = "players.online.unsubscribe";

// Get online status every 1 sec
const getOnlineConstantly = 1000;

const usenowait = true;

export default {
  name: "UsersList",
  components: {
    UsersFilter,
    UsersFilterInventory,
    UsersListActions,
    PlayerOnline,
  },

  props: {
    game: String,
  },

  data() {
    return {
      error: "",
      usersList: [],
      previous: "",
      filter: "",
      gamePrev: "",
      processGetPage: true,
    };
  },

  mounted() {
    let that = this;

    // Add 'reader' which will receive data from WebRTC Data Channel
    this.reader = this.teoweb.addReader(function (gw, data) {
      // Process answer to cmdUsersList command
      if (gw.command.startsWith(cmdUsersList)) {
        that.processGetPage = false;

        if (gw.err) {
          that.error = "Error: " + gw.err;
          return;
        }
        that.error = "";
        that.webasmReady.then(() => {
          data = window.decompressData(data);
          const answer = JSON.parse(data);
          that.previous = answer.Pagination;
          if (answer.Users) {
            that.usersList = that.usersList.concat(answer.Users);
          }
        });
      }

      // Process answer to cmdPlayersOnline command
      if (usenowait && gw.command.startsWith(cmdPlayersOnline)) {
        if (gw.err) {
          return;
        }
        that.processPlayersOnlineAnswer(data);
      }
    });

    // Get player online status every 5 seconds
    getOnlineConstantly &&
      (this.intervalId = setInterval(this.getOnline, getOnlineConstantly));

    // Check list scroll to bottom
    window.addEventListener("scroll", this.scrollListener);
  },

  unmounted: function () {
    this.teoweb.delReader(this.reader);
    getOnlineConstantly && clearInterval(this.intervalId);
    window.removeEventListener("scroll", this.scrollListener);
    this.teoweb.sendCmd(cmdPlayersOnlineUnsubscribe);
  },

  methods: {
    /**
     * scrollListener - Checks whether the user has scrolled to the bottom of the list.
     * If so, it calls getNextPage to get the next page of players.
     *
     * This function is called every time the user scrolls.
     */
    scrollListener() {
      const scrollTop = window.scrollY;
      const scrollHeight = document.body.scrollHeight;
      const clientHeight = window.innerHeight;

      if (scrollTop + clientHeight + 300 >= scrollHeight) {
        if (this.previous && !this.error && !this.processGetPage) {
          this.getNextPage(this.filter);
        }
      }
    },

    /**
     * getOnline - Sends a command to the WebRTC server to get the online
     * status of all players.
     */
    getOnline() {
      // Skip if users list is empty
      if (this.usersList.length == 0) {
        return;
      }

      // sendCmdPlayersOnline sends cmdPlayersOnline command to WebRTC server,
      // wait answer and set online status to users list
      const sendCmdPlayersOnline = (players) => {
        // Skip for all games mode
        if (this.allGames()) {
          if (this.playerssave) {
            this.teoweb.sendCmd(cmdPlayersOnlineUnsubscribe);
            this.playerssave = "";
          }
          return;
        }

        // Send cmdPlayersOnline command to WebRTC server
        if (usenowait) {
          // Skip if players list not changed
          if (this.playerssave == players) {
            return;
          }
          this.playerssave = players;

          // Send request
          this.teoweb.sendCmd(cmdPlayersOnline + "/" + this.game, players);
          return;
        }

        // Send cmdPlayersOnline command to WebRTC server and process answer
        this.sendCmd(cmdPlayersOnline + "/" + this.game, players).then(
          // Process success answer
          (data) => {
            this.processPlayersOnlineAnswer(data);
          },

          // Process an error
          (err) => {
            console.error("cmdPlayersOnline error:", err);
          }
        );
      };

      // Create players sub string list from users list if user is visible
      const players = this.usersList
        .filter((user) =>
          this.isElementVisible(
            this.getDivElementByName(this.AttributesMap(user.Attributes)["sub"])
          )
        )
        .map((user) => this.AttributesMap(user.Attributes)["sub"]);

      // Compress players sub string
      this.webasmReady.then(() =>
        sendCmdPlayersOnline(window.compressData(players.join(",")))
      );
    },

    /**
     * processPlayersOnlineAnswer - Process answer to cmdPlayersOnline command
     *
     * @param {string} data - Answer to cmdPlayersOnline command
     */
    processPlayersOnlineAnswer(data) {
      // Get array of onlines
      const onlines = JSON.parse(data);

      // Skip empty onlines
      if (!onlines) {
        return;
      }

      // Loop by onlines and set online status to user
      for (const online of onlines) {
        const user = this.usersList.find(
          (user) => this.AttributesMap(user.Attributes)["sub"] == online.sub
        );
        if (user) {
          user.online = online;
        }
      }
    },

    /**
     * Returns true if the given element is visible in the viewport, false otherwise.
     *
     * @param {Element} element - The element to check
     * @returns {boolean}
     */
    isElementVisible(element) {
      if (!element) {
        return false;
      }

      const rect = element.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
          (window.innerWidth || document.documentElement.clientWidth)
      );
    },

    /**
     * Finds a div element with the given name attribute and returns it.
     *
     * @param {string} name - The name of the div element to find
     * @returns {Element} The found div element
     */
    getDivElementByName(name) {
      return document.querySelector(`div[name="${name}"]`);
    },

    /**
     * getNextPage - Sends a command to the WebRTC server to get the next
     * page of users.
     */
    getNextPage(filter, refresh = false) {
      if (refresh) {
        this.usersList = [];
        this.previous = "";
      }
      this.processGetPage = true;
      this.sendCmdUsersList(filter);
    },

    /**
     * sendCmdUsersList - Sends a command to the WebRTC server to get the next
     * page of users.
     */
    sendCmdUsersList(filter = "") {
      this.teoweb.sendCmd(
        cmdUsersList + this.previous + "/" + this.game,
        filter
      );
    },

    /**
     * setFilter sets filter and filterType and call getNextPage.
     * @param type
     * @param filter
     */
    setFilter(filter) {
      this.filter = filter;
      this.getNextPage(filter, true);
    },

    /**
     * AttributesMap - Returns a map of attributes created from an array of
     * attribute objects. Each attribute object has two properties: Name and
     * Value. The function uses the reduce function to create a new map with
     * the names as the keys and the values as the values.
     *
     * @param {Array} attributes - An array of attribute objects.
     * @return {Object} - A map of attributes.
     */
    AttributesMap(attributes) {
      // Return the map of attributes
      return attributes.reduce((map, element) => {
        map[element.Name] = element.Value;
        return map;
      }, {});
    },

    /**
     * allGames - Returns true if the current game is "All games".
     * @return {boolean} - True if the current game is "All games".
     */
    allGames() {
      return this.game == "" || this.game == allGames;
    },

    /**
     * sizeColor - Returns the size color based on the data length class.
     *
     * The function takes a data length class as a parameter and returns the
     * corresponding size color.
     *
     * @param {string} dataLengthClass - The data length class.
     * @return {string} - The size color.
     */
    sizeColor(dataLengthClass) {
      if (dataLengthClass == "primary") {
        return "";
      }
      return "text-" + dataLengthClass;
    },
  },

  watch: {
    // Watch for changes in the game property
    game: function () {
      // If switch beatween games (exclude All games)
      if (
        !(
          this.game != this.gamePrev &&
          (this.game == allGames || this.gamePrev == allGames)
        )
      ) {
        this.getNextPage(this.filter, true);
      }

      this.gamePrev = this.game;
    },
  },
};
</script>
