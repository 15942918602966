<template>
  <div v-if="online.online">
    <span class="badge bg-success">Online</span> in {{ online.level }}
  </div>
  <!-- <div v-else class="text-danger">offline</div> -->
</template>

<script>
const allGamesLabel = "All games";
const cmdPlayerOnline = "player.online/";

export default {
  name: "PlayerOnline",

  props: {
    game: String,
    sub: String,
    setOnline: Object,
  },

  emits: ["online"],

  data() {
    return {
      online: { online: false, level: "" },
    };
  },

  mounted() {
    let that = this;

    // Skip if game is all games
    if (!this.game || this.game === allGamesLabel) {
      return;
    }

    // Request player online status when logged in
    const getOnline = () => {
      this.sendCmd(cmdPlayerOnline + that.game + "/" + that.sub).then(
        // Process success answer
        (data) => (that.set(JSON.parse(data))),
        // Process an error
        (err) => console.error("cmdPlayerOnline error:", err)
      );
    };

    // Get player online status now
    getOnline();
  },

  watch: {
    setOnline(val) {
      this.online = val;
    },
  },

  methods: {
    set(val) {
      this.online = val;
      this.$emit("online", val);
    },
  },
};
</script>
